require('../js/fancybox/jquery.fancybox.css');
require('@fortawesome/fontawesome-free/css/all.min.css');
require('../js/rgpd-simple/rgpd-simple.css');
require('jquery-ui/ui/widgets/sortable');
require('../css/app.scss');
require('../js/trumbowyg/ui/icons.svg');
require('../js/trumbowyg/ui/trumbowyg.min.css');

// Images
const imagesContext = require.context('../images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp|pdf)$/);
imagesContext.keys().forEach(imagesContext);

// PDFS
const pdfsContext = require.context('../pdfs', true, /\.(pdf)$/);
pdfsContext.keys().forEach(pdfsContext);

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;

// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything

/***************************
 * ROUTING JS
 * ex :
 * router.routing.generate('monurl')
 * router.routing.generate('monurl2', {'param1': 'value1', 'params2': 'value2})
 *
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * Les routes routes doivent avoir l'option expose true, ex :
 *
 backoffice/ajax-sort:
 path: backoffice/ajax-sort
 controller: App\Controller\Backoffice\BackofficeController:ajaxSort
 options:
 expose: true

 Après la création d'une nouvelle route vous devez également lancer la commande suivante pour récupérer les routes dans le JS

 php bin/console fos:js-routing:dump --format=json --target=public/js/fos_js_routes.json

 puis compiler les assets

 yarn encore dev
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 */

// import le fichier router dans ce fichier
const router = require('./router.js');
// permet son utilisation dans les fichiers twig
global.router = router;

$(document).ready(function ($) {


    /*********************************************
     * LEGENDS
     */
    $(document).on({
        click: function () {
            $(this).next('fieldset').slideToggle();
            if ($(this).hasClass('active')) {
                $(this).removeClass('active')
            } else {
                $(this).addClass('active');
            }
        }
    }, 'legend');


    /*********************************************
     * ANIMAL ADD NAME IN LEGENDS (ETAPE ENREGISTREMENT NOUVEL ANIMAL)
     */

    $('#animal_usual_name input').each(function () {

        let firstname = $(this).val();
        let familyName = " "
        if ($('#animal_name').length) {
            familyName += $(this).closest('.animal_content').find('#animal_name input').val();
        }
        $(this).next('legend .text_legend').text(firstname);
        let name = firstname + " - " + familyName;
        $(this).closest('.animal_content').find('legend .text_legend').text(name);

    })


    /***************************
     * MENU
     */
    var mobileViewports = ['xs', 'sm', 'md'];
    if (mobileViewports.indexOf(getBootstrapViewport()) >= 0) {
        $('#menu-site').hcOffcanvasNav({
            maxWidth: false,
            // levelOpen: 'expand',
            labelClose: 'Fermer',
            labelBack: 'Retour',
            levelSpacing: 10,
            position: 'right',
            levelOpen: 'overlap'
        });

    }
    $('#menu-sidebar').hcOffcanvasNav({
        maxWidth: false,
        // levelOpen: 'expand',
        labelClose: 'Fermer',
        labelBack: 'Retour',
        levelOpen: 'overlap',
        levelSpacing: 10
    });

    /**************************
     * ECOUTEUR SUR LA TAILLE DES IMAGES / DOCUMENT
     */
    $(document).on({
        change: function () {
            var fileExtension = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'pdf'];
            if ($.inArray($(this).val().split('.').pop().toLowerCase(), fileExtension) == -1) {
                alert("Formats autorisés : " + fileExtension.join(', '));
            }
            if (this.files[0].size > 10485760) {
                alert("Le fichier excède 10Mo.");
            }
        }
    }, '.check_size');

    $(document).on({
        change: function () {
            var fileExtension = ['jpeg', 'jpg', 'png', 'gif', 'bmp', 'pdf'];
            if ($.inArray($(this).val().split('.').pop().toLowerCase(), fileExtension) == -1) {
                alert("Formats autorisés : " + fileExtension.join(', '));
            }
            if (this.files[0].size > 5242880) {
                alert("Le fichier excède 2Mo.");
            }
        }
    }, '.check_size_attachments');

    /**************************
     * ECOUTEUR AJAX INPUT TEXT
     */
    $(document).on({
        keyup: function () {
            var thisElt = $(this);
            // l'url de l'ajax
            var url = null;
            if (typeof thisElt.attr('data-url') != 'undefined') {
                url = thisElt.attr('data-url');
            }
            if (!url) {
                url = router.routing.generate('backoffice/ajax-edit');
            }
            // l'id de l'élément à modifier en base
            var id = thisElt.attr('data-id');
            // le champ qui va être modifié
            var field = thisElt.attr('data-field');
            // la value qui va être modifié
            var value = thisElt.val();
            // le nom de l'entité qui va être modifié (en chemin complet) ex : User\User, Actuality\ActualityCategory
            var entity = thisElt.attr('data-entity');

            if (typeof id == 'undefined' || typeof field == 'undefined' || typeof entity == 'undefined') {
                return false;
            }

            // on met un timer pour ne trigger l'event qu'une fois
            waitForFinalEvent(function () {
                toggleEdit({
                    url: url,
                    thisElt: thisElt,
                    id: id,
                    field: field,
                    value: value,
                    entity: entity
                });
            }, 500, 'keyup-ajax-edit');
        }
    }, 'input[type="text"].ajax-edit');

    /***************************
     * ECOUTEUR BOUTONS TOGGLE
     * Pour mettre une url d'ajax différente l'élément doit avoir un attribut data-url avec en valeur la route, ex dans un twig :
     * ... data-url="{{ path('monurl') }}" />
     */
    $(document).on({
        change: function () {
            // l'élément sur lequel on clic
            var thisElt = $(this);
            // l'url de l'ajax
            var url = null;
            if (typeof thisElt.attr('data-url') != 'undefined') {
                url = thisElt.attr('data-url');
            }
            // l'id de l'élément à modifier en base
            var id = thisElt.attr('data-id');
            // le champ qui va être modifié
            var field = thisElt.attr('data-field');
            // la value qui va être modifié
            var value = thisElt.prop('checked') ? 1 : 0;
            // le nom de l'entité qui va être modifié (en chemin complet) ex : User\User, Actuality\ActualityCategory
            var entity = thisElt.attr('data-entity');

            if (typeof id == 'undefined' || typeof field == 'undefined' || typeof entity == 'undefined') {
                return false;
            }

            if (thisElt.hasClass('toggle-unique')) {
                if (!url) {
                    url = router.routing.generate('backoffice/ajax-toggle-unique');
                }
                var othersId = null;
                if (typeof thisElt.attr('data-get-others-id') != 'undefined') {
                    othersId = '';
                    thisElt.parents('tbody').find('tr').each(function () {
                        var thisOther = $(this);
                        if (typeof thisOther.attr('data-id') != 'undefined' && thisOther.attr('data-id') != id) {
                            othersId += thisOther.attr('data-id') + ';';
                        }
                    });
                }
                toggleEditUnique({
                    url: url,
                    thisElt: thisElt,
                    id: id,
                    field: field,
                    entity: entity,
                    othersId: othersId
                });
            } else {
                if (!url) {
                    url = router.routing.generate('backoffice/ajax-edit');
                }

                toggleEdit({
                    url: url,
                    thisElt: thisElt,
                    id: id,
                    field: field,
                    value: value,
                    entity: entity
                });
            }
        }
    }, 'input[data-toggle="toggle"]');

    /****************************************************************************************************
     * ECOUTEUR POUR LES COLLECTION TYPES
     */
    $('.add-another-collection-widget').not('#animal .add-another-collection-widget').click(function (e) {

        var list = jQuery(jQuery(this).attr('data-list-selector'));
        var counter = list.data('widget-counter') || list.children().length;
        // grab the prototype template
        var newWidget = list.attr('data-prototype');
        newWidget = newWidget.replace(/__name__/g, counter);

        // Increase the counter
        counter++;
        list.data('widget-counter', counter);
        // create a new list element and add it to the list
        var newElem = jQuery(list.attr('data-widget-tags')).html(newWidget);
        newElem.appendTo(list);

        let thisAddVaccin = $(newElem).find('.add-another-collection-widget-animal');

        console.log(counter--);
        $(thisAddVaccin).attr('collection_id', counter--);


        if ($('.assurance_title_js').length) {
            $('.assurance_title_js').each(function () {
                if (!$(this).val()) {
                    $(this).val('Assurance');
                }
            });
        }

        if ($('.amount_membership').length) {
            $(document).on({
                keyup: function () {
                    let value = $(this).val();
                    if (value > 0) {
                        $(this).parent().parent().find('.comment input').prop('required', false);
                    } else {
                        $(this).parent().parent().find('.comment input').prop('required', true);
                    }
                }
            }, '.amount_membership input');
        }

        $('.animal_race').on('select2:select', function (e) {
            var data = e.params.data;
            if (data.text === 'Croisé') {
                $(this).parent().parent().find('.croise').show();
                $(this).parent().parent().find('.croise input').prop('required', true);
            } else {
                $(this).parent().parent().find('.croise').hide();
                $(this).parent().parent().find('.croise input').prop('required', false);
            }
        });

        // NOM DE L'ADHERENT POUR LE LEGEND BLOCK
        if ($('#last_name_steps').length) {
            $(document).on({
                keyup: function () {
                    let firstname = $(this).val();
                    let familyName = $(this).closest('.member_content').find('#last_name_steps input').val();
                    $(this).next('legend .text_legend').text(firstname);
                    let name = familyName + " " + firstname;
                    $(this).closest('.member_content').find('legend .text_legend').text(name);

                }
            }, '#first_name_steps input');

            $(document).on({
                keyup: function () {
                    let firstname = $(this).closest('.member_content').find('#first_name_steps input').val();
                    let familyName = $(this).val();
                    $(this).next('legend .text_legend').text(firstname);
                    let name = familyName + " " + firstname;
                    $(this).closest('.member_content').find('legend .text_legend').text(name);

                }
            }, '#last_name_steps input');
        }

        // NOM DE L'ANIMAL POUR LE LEGEND BLOCK
        if ($('#animal_usual_name').length) {
            $(document).on({
                keyup: function () {
                    let firstname = $(this).val();
                    let familyName = " "
                    if ($('#animal_name').length) {
                        familyName += $(this).closest('.animal_content').find('#animal_name input').val();
                    }
                    $(this).next('legend .text_legend').text(firstname);
                    let name = familyName + " " + firstname;
                    $(this).closest('.animal_content').find('legend .text_legend').text(name);
                }
            }, '#animal_usual_name input');
        }
        if ($('#animal_name').length) {
            $(document).on({
                keyup: function () {
                    let firstname = $(this).closest('.animal_content').find('#animal_usual_name input').val();
                    let familyName = $(this).val();
                    $(this).next('legend .text_legend').text(firstname);
                    let name = familyName + " " + firstname;
                    $(this).closest('.animal_content').find('legend .text_legend').text(name);
                }
            }, '#animal_name input');
        }

        /***************************
         * ECOUTEUR DATEPICKER
         */
        jQuery(document).ready(function () {
            $('.js-datepicker').datepicker({
                language: "fr",
                orientation: "bottom auto",
                weekStart: 1,
                autoclose: true,
                clearBtn: true,
            });
        });

        (function ($) {
            $.fn.datepicker.dates['fr'] = {
                days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
                daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
                daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
                months: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
                monthsShort: ["janv.", "févr.", "mars", "avril", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
                today: "Aujourd'hui",
                monthsTitle: "Mois",
                clear: "Effacer",
                weekStart: 1,
                format: "dd/mm/yyyy"
            };
        }(jQuery));

        /***************************
         * MODIF AFFICHAGE DES BOUTON UPLOAD
         */

        if ($('.trueInput').length) {
            $(document).on({
                change: function () {
                    let trueInput = $(this);
                    let fakeInput = $(trueInput).closest('#FakeInput').find('.return');
                    let trueValue = $(trueInput).val();
                    $(fakeInput).text(trueValue.replace(/C:\\fakepath\\/i, ''))
                    // fakeInput.innerHTML = trueValue.replace(/C:\\fakepath\\/i, '');
                    $(trueInput).closest('#FakeInput').find('.label_image').hide();
                    $(trueInput).closest('#FakeInput').find('.return').css('margin-bottom', '10px');
                }
            }, '.trueInput');
        }

        // search bar in selects
        if ($('.select_search').length) {
            $('.select_search').select2();
        }

        /**
         * ADRESSES VALUE FROM GOUV API
         */
        let requestURL = 'https://api-adresse.data.gouv.fr/search/?q='
        var select = $('#selection_address');
        $("#family_solo_addresses_0_enterAdress").autocomplete({
            source: function (request, response) {
                $.ajax({
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + $("#family_solo_addresses_0_enterAdress").val(),
                    data: {q: request.term},
                    dataType: "json",
                    success: function (data) {
                        autocompleteAdresseSolo()
                    }
                });
            },
        });

        function autocompleteAdresseSolo() {
            var inputValue = $("#family_solo_addresses_0_enterAdress").val()
            if (inputValue) {
                fetch(setQuerySolo(inputValue))
                    .then(function (response) {
                        response.json().then(function (data) {
                            displaySelectionSolo(data);
                        });
                    });
            } else {
                $(select).hide()
            }
        }

        function selectAdresseSolo(element) {
            $("#family_solo_addresses_0_enterAdress").val(element.properties.name + " " + element.properties.postcode + " " + element.properties.city);
            $(select).hide()
            $("#family_solo_addresses_0_street").val(element.properties.name);
            $("#family_solo_addresses_0_zip").val(element.properties.postcode);
            $("#family_solo_addresses_0_city").val(element.properties.city);
        }

        function setQuerySolo(value) {
            return requestURL + value + "?type=housenumber&autocomplete=1";
        }

        function displaySelectionSolo(response) {
            if (Object.keys(response.features).length > 0) {
                $(select).show();
                var ul = document.createElement('ul');
                $(select).html(ul);
                response.features.forEach(function (element) {
                    var li = document.createElement('li');
                    var ligneAdresse = document.createElement('span');
                    var infosAdresse = document.createTextNode(element.properties.postcode + ' ' + element.properties.city);

                    ligneAdresse.innerHTML = element.properties.name;
                    li.onclick = function () {
                        selectAdresseSolo(element);
                    };
                    li.appendChild(ligneAdresse);
                    li.appendChild(infosAdresse);
                    ul.appendChild(li);
                });
            } else {
                $(select).hide()
            }
        }

        if ($("#family_solo_addresses_0_enterAdress").length) {
            $('.add-another-collection-widget.btn.family_add').hide()
        }


    });

    /**
     * END ECOUTEUR POUR LES COLLECTION TYPES
     ***************************************************************************************************/

    $(document).on({
        click: function () {
            $(this).parents('.row_delete').remove();
            return false;
        }
    }, '.btn-delete:not("#animal .btn-delete")');

    /****************************************************************************************************
     * DOCS PREVIEW
     */
    $(document).on({
        click: function () {
            var documentId = $(this).attr("id");
            $.ajax({
                type: "POST",
                url: router.routing.generate('ajax-document-preview'),
                data: 'documentId=' + documentId,
                dataType: 'json',
                success: function (msg) {
                    var documentView = '';
                    var path = '/uploads/documents';
                    documentView += '<div class="preview_front_box"><div class="preview_box">' +
                        '<a target="_blank"  href="' + path + '/' + msg.documentView + '">' +
                        ' <iframe src="' + path + '/' + msg.documentView + '" style="width: 100%;height: 80vh" /></iframe>' +
                        '</a>' +
                        '<div  id="close_preview" class="btn btn-er btn-delete btn-xs" onclick="closePreview()" >' +
                        '       <i class="fa fa-times"></i>' +
                        '</div></div></div>';
                    document.getElementById('preview-front').innerHTML = documentView;
                }
            });
        }
    }, '.see_document');

    $(document).on({
        click: function () {
            $('#preview-front').empty();
        }
    }, '#close_preview');

    /**
     * DOCS PREVIEW
     ***************************************************************************************************/

    // organisme field remove is contact == ercontact
    $(".er-contact-removal").hide();

    // search bar in selects
    $('.select_search').select2();

    /***************************
     * ECOUTEUR BOUTONS DELETE ENTITY GROUPEE
     * Pour mettre une url d'ajax différente l'élément doit avoir un attribut data-url avec en valeur la route, ex dans un twig :
     * ... data-url="{{ path('monurl') }}" />
     // */
    $(document).on({
        click: function () {
            // get group choice
            var e = document.getElementById("action-element");
            var actionSelected = e.options[e.selectedIndex].value;
            let actionValue = e.options[e.selectedIndex].text;
            if (!actionSelected) {
                if (!confirm('Aucune action sélectionnée')) {
                    return false;
                }
            } else {
                if (!confirm(actionValue + ' ?')) {
                    return false;
                } else {
                    // l'id de l'élément à modifier en base
                    let ids = [];
                    $("input:checkbox[name=action-groupee]:checked").each(function () {
                        ids.push($(this).val());
                    });
                    if (ids.length >= 1) {
                        // l'élément sur lequel on clic
                        var thisElt = $(this);
                        // l'url de l'ajax
                        var url = router.routing.generate('backoffice/ajax-action-group-entity');
                        if (typeof thisElt.attr('data-url') != 'undefined') {
                            url = thisElt.attr('data-url');
                        }
                        // le nom de l'entité qui va être modifié (en chemin complet) ex : User\User, Actuality\ActualityCategory
                        var entity = $('table.table').attr('data-entity');
                        $.ajax({
                            type: "POST",
                            url: url,
                            dataType: 'json',
                            data: 'ids=' + ids + '&entity=' + entity + '&actionSelected=' + actionSelected,
                            success: function (msg) {
                                if (msg.error) {
                                    showAlert({
                                        class: 'alert-danger',
                                        message: 'Une erreur est survenue'
                                    });
                                } else {
                                    document.location.reload(true)
                                }
                            }
                        });
                        return false;
                    } else {
                        alert('Aucune donnée à supprimer !!')
                    }
                }
            }
        }
    }, '.btn-group-action-entities');


    /***************************
     * AFFICHER ALERTE
     */
    global.showAlert = function (params) {
        var alert = $('<div class="alert-fixed"><div class="alert ' + params.class + '">' + params.message + '</div></div>').prependTo('body');
        setTimeout(function () {
            removeAlert({
                element: alert
            });
        }, 3000);
    }

    /***************************
     * CACHER ALERTE
     */
    global.removeAlert = function (params) {
        if (typeof $(params.element) != 'undefined' && $(params.element).length) {
            $(params.element).fadeOut('slow', function () {
                $(params.element).remove();
            })
        }
    }

    /*****************************
     * TABLEAU TRIABLES
     */
    $('table.sortable tbody').sortable({
        placeholder: "ui-state-highlight",
        update: function () {
            // Sauvegarde l'ordre
            if (typeof savePositions != 'undefined') {
                savePositions({table: $(this).parents('table')});
            }
        },
        helper: function (e, tr) {
            var originals = tr.children();
            var helper = tr.clone();
            helper.children().each(function (index) {
                // Set helper cell sizes to match the original sizes
                $(this).width(originals.eq(index).width());
            });
            return helper;
        }
    });

    /*****************************
     * TABLEAU TRIABLES
     * Pour personalisé l'url ajax de tri la table doit avoir un attribut data-url_sortable, ex:
     * <table data-url_sortable="{{ path('monurl') }}">
     */

    function savePositions(p) {
        // recupère la liste des ids
        var strIds = '';
        $('tr', p.table.find('tbody')).each(function (index) {
            strIds += parseInt($(this).attr('data-id')) + ',';
        });

        // url de tri par défaut
        var urlSortable = router.routing.generate('backoffice/ajax-sort');

        // si on a une url spécifique
        if (typeof p.table.attr('data-url_sortable') != 'undefined') {
            urlSortable = p.table.attr('data-url_sortable');
        }

        // vérifie qu'on a bien l'entité
        if (typeof p.table.attr('data-entity') == 'undefined') {
            showAlert({
                class: 'alert-danger',
                message: 'Impossible de trouver l\'entité associée'
            });
            return false;
        }

        // la requete ajax
        $.ajax({
            url: urlSortable,
            type: "POST",
            data: "strIds=" + strIds + '&entity=' + p.table.attr('data-entity'),
            dataType: 'json',
            success: function (result) {
                if (result.error == 1) {
                    showAlert({
                        class: 'alert-danger',
                        message: result.message
                    });

                } else {
                    if (result.message) {
                        showAlert({
                            class: 'alert-success',
                            message: result.message
                        });
                        document.location.reload();
                    }
                }
            }
        });
    }

    /***************************
     * DISPLAY DES TABLES
     */

    /*****************************
     * DATATABLES
     */
    $('.table.dataTable').each(function () {
        let colonneOrder = $(this).attr('data-colonneOrder');
        let directionOrder = $(this).attr('data-directionOrder');
        var columnDefs = [];

        if (typeof ($(this).attr('data-columnDefs')) != 'undefined') {
            var arrayColumnDefs = JSON.parse($(this).attr('data-columnDefs'));
            for (var i = 0; i < arrayColumnDefs.length; i++) {
                columnDefs.push(arrayColumnDefs[i]);
            }
        }
        var table = $(this).DataTable({
            "order": [[colonneOrder, directionOrder]],
            "columnDefs": columnDefs,
            "language": {
                "processing": "Traitement en cours...",
                "search": "Rechercher&nbsp;:",
                "lengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
                "info": "Affichage de l'élément _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                "infoEmpty": "Affichage de l'élément 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
                "infoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                "infoPostFix": "",
                "loadingRecords": "Chargement en cours...",
                "zeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
                "emptyTable": "Aucune donn&eacute;e disponible dans le tableau",
                "paginate": {
                    "first": "Premier",
                    "previous": "Pr&eacute;c&eacute;dent",
                    "next": "Suivant",
                    "last": "Dernier"
                },
                "aria": {
                    "sortAscending": ": activer pour trier la colonne par ordre croissant",
                    "sortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
                }
            },
            "pageLength": 50,
            "lengthMenu": [[10, 25, 50, 100, -1], [10, 25, 50, 100, "Tout"]]
        });


        if ($(this).hasClass('filters-footer')) {

            $("tfoot th", this).each(function (i) {
                var select = $('<select><option value=""></option></select>')
                    .appendTo($(this).empty())
                    .on('change', function () {
                        table.column(i)
                            .search($(this).val())
                            .draw();
                    });

                table.column(i).data().unique().sort().each(function (d, j) {
                    select.append('<option value="' + d + '">' + d + '</option>')
                });
            });
        }
    });

    $('.table.dataTableRaces').each(function () {
        let colonneOrder = $(this).attr('data-colonneOrder');
        let directionOrder = $(this).attr('data-directionOrder');
        var columnDefs = [];

        if (typeof ($(this).attr('data-columnDefs')) != 'undefined') {
            var arrayColumnDefs = JSON.parse($(this).attr('data-columnDefs'));
            for (var i = 0; i < arrayColumnDefs.length; i++) {
                columnDefs.push(arrayColumnDefs[i]);
            }
        }
        var table = $(this).DataTable({
            "order": [[colonneOrder, directionOrder]],
            "columnDefs": columnDefs,
            "language": {
                "processing": "Traitement en cours...",
                "search": "Rechercher&nbsp;:",
                "lengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
                "info": "Affichage de l'élément _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                "infoEmpty": "Affichage de l'élément 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
                "infoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                "infoPostFix": "",
                "loadingRecords": "Chargement en cours...",
                "zeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
                "emptyTable": "Aucune donn&eacute;e disponible dans le tableau",
                "paginate": {
                    "first": "Premier",
                    "previous": "Pr&eacute;c&eacute;dent",
                    "next": "Suivant",
                    "last": "Dernier"
                },
                "aria": {
                    "sortAscending": ": activer pour trier la colonne par ordre croissant",
                    "sortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
                }
            },
            "pageLength": 100
        });


        if ($(this).hasClass('filters-footer')) {

            $("tfoot th", this).each(function (i) {
                var select = $('<select><option value=""></option></select>')
                    .appendTo($(this).empty())
                    .on('change', function () {
                        table.column(i)
                            .search($(this).val())
                            .draw();
                    });

                table.column(i).data().unique().sort().each(function (d, j) {
                    select.append('<option value="' + d + '">' + d + '</option>')
                });
            });
        }
    });

    $('#export-views .table_export.dataTable').DataTable({
        buttons: [
            'copyHtml5', 'excelHtml5', 'pdfHtml5', 'print', 'colvis', 'pageLength'
        ],
        // dom: "<'row'<'col-lg-5 pt-2'f><'col-lg-7 text-right'B>>" + "<'row'<'col-12'tr>>" + "<'row'<'col-lg-6'i><'col-lg-6'p>>",
        dom: "<'row'<'col-lg-5 pt-2'fl><'col-lg-7 text-right'B>>" + "<'row'<'col-12'tr>>" + "<'row'<'col-lg-6'i><'col-lg-6'p>>",
        language: {
            "processing": "Traitement en cours...",
            "search": "Rechercher :",
            "lengthMenu": "Afficher _MENU_",
            "info": "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
            "infoEmpty": "Affichage de l'élément 0 à 0 sur 0 éléments",
            "infoFiltered": "(filtré de _MAX_ éléments au total)",
            "infoPostFix": "",
            "loadingRecords": "Chargement en cours...",
            "zeroRecords": "Aucun élément à afficher",
            "emptyTable": "Aucune donnée disponible dans le tableau",
            "paginate": {
                "first": "Premier",
                "previous": "Précédent",
                "next": "Suivant",
                "last": "Dernier"
            },
            "aria": {
                "sortAscending": ": activer pour trier la colonne par ordre croissant",
                "sortDescending": ": activer pour trier la colonne par ordre décroissant"
            },
            "decimal": ",",
            "thousands": ".",
            buttons: {
                copy: 'Copier',
                print: 'Imprimer',
                colvis: 'Colonne',
                pageLength: {
                    _: "Afficher %d éléments",
                    '-1': "Tout afficher"
                },
            }
        },
        order: [[0, 'ASC']],
        pageLength: 100,
        lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "Tout"]],
    });

    /*****************************
     * wysiwyg
     */
    var simpleWysiwygs = [];
    $('textarea.simple-wysiwyg').each(function () {
        var thisElt = $(this);
        var currentWysiwyg = thisElt.trumbowyg({
            lang: 'fr',
            svgPath: thisElt.attr('data-svgPath'),
            btnsDef: {
                // Customizables dropdowns
                image: {
                    dropdown: ['insertImage', 'upload'],
                    ico: 'insertImage'
                }
            },
            btns: [
                ['viewHTML'],
                ['undo', 'redo'],
                ['formatting'],
                ['fontsize'],
                ['strong', 'em', 'del', 'underline'],
                ['link'],
                ['image'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['foreColor', 'backColor'],
                ['preformatted'],
                ['horizontalRule'],
                ['fullscreen'],
                ['historyUndo', 'historyRedo']
            ],
            plugins: {
                upload: {
                    // Some upload plugin options, see details below
                    serverPath: thisElt.attr('data-ajax-upload-url'),
                    // nom du post de l'image
                    fileFieldName: 'fileToUpload',
                    // nom de l'url de l'image dans le retour json
                    urlPropertyName: 'pictureUrl',
                    // autres paramètres à passer
                    data: [
                        {name: 'entity', value: thisElt.attr('data-entity')},
                        {name: 'id', value: thisElt.attr('data-id')}
                    ],
                    imageWidthModalEdit: true,
                },
                // resizimg: {
                //     minSize: 64,
                //     step: 16,
                // }
            }
        });

        simpleWysiwygs.push(currentWysiwyg);
    });

    /***************************
     * LANGUAGE SWITCHER
     */
    $(document).on({
        change: function () {
            var thisElt = $(this);
            $.ajax({
                type: "POST",
                url: router.routing.generate('ajax/set-locale'),
                dataType: 'json',
                data: {
                    language: thisElt.val()
                },
                success: function (msg) {
                    if (!msg.success) {
                        showAlert({
                            class: 'alert-danger',
                            message: 'Une erreur est survenue'
                        });
                    } else {
                        if (thisElt.find('option:selected').attr('data-url')) {
                            location.href = thisElt.find('option:selected').attr('data-url');
                        } else {
                            document.location.reload();
                        }
                    }
                }
            });
        }
    }, 'select[name="language-switcher"]');

    /***************************
     * ECOUTEUR PASSWORD SEEABLE
     */
    $(document).on({
        click: function () {
            $(this).parent().find('input[type="password"]').prop('type', 'text');
            return false;
        }
    }, '.password-seeable i');

    /***************************
     * ECOUTEUR DATEPICKER
     */
    jQuery(document).ready(function () {
        $('.js-datepicker').datepicker({
            language: "fr",
            orientation: "bottom auto",
            weekStart: 1,
            autoclose: true,
            clearBtn: true,
        });
    });

    /***************************
     * AJOUT NOM ASSURANCE PAR DEFAULT DANS LES FORMULAIRES
     */

    jQuery(document).ready(function () {

        if ($('.assurance_title_js').length) {
            $('.assurance_title_js').each(function () {
                if (!$(this).val()) {
                    $(this).val('Assurance');
                }
            });
        }

    });

    (function ($) {
        $.fn.datepicker.dates['fr'] = {
            days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
            daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
            daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
            months: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
            monthsShort: ["janv.", "févr.", "mars", "avril", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
            today: "Aujourd'hui",
            monthsTitle: "Mois",
            clear: "Effacer",
            weekStart: 1,
            format: "dd/mm/yyyy"
        };
    }(jQuery));

    $(document).on({
        click: function () {
            $(this).parents('.datepicker').find('.datepicker-widget').toggleClass('visible')
            $(this).parents('.datepicker').find('.birthdaypicker-widget').toggleClass('visible')
        }
    }, '.datepicker-widget-wrapper i');

    /***************************
     * ECOUTEUR FANCYBOX
     */
    if ($().fancybox) {
        // Fermeture fancybox
        $(document).on({
            click: function () {
                $.fancybox.close();
                return false;
            }
        }, '.btn-close-fancybox-custom');
    }

    if ($('.trueInput').length) {
        $(document).on({
            change: function () {
                let trueInput = $(this);
                let fakeInput = $(trueInput).closest('#FakeInput').find('.return');
                let trueValue = $(trueInput).val();
                $(fakeInput).text(trueValue.replace(/C:\\fakepath\\/i, ''))
                // fakeInput.innerHTML = trueValue.replace(/C:\\fakepath\\/i, '');
                $(trueInput).closest('#FakeInput').find('.label_image').hide();
                $(trueInput).closest('#FakeInput').find('.return').css('margin-bottom', '10px');
            }
        }, '.trueInput');
    }


    /*****************************
     *  si mineur, on affiche l'autorisation parentale
     */
    $(document).ready(function ($) {
        function getAge(DOB) {
            var today = new Date();
            var birthDate = new Date(DOB);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }

        $(document).on({
            change: function () {
                let elts = $(this);
                let dateSelected = elts.datepicker('getDate');
                let enteredAge = getAge(dateSelected);
                console.log(enteredAge);
                if (enteredAge <= 18) {
                    $(this).closest('.row').find(".auto_parental").show();
                    $(this).closest('.row').find(".auto_parental select").prop('required', true);
                    return false;
                } else {
                    $(this).closest('.row').find(".auto_parental").hide();
                    $(this).closest('.row').find(".auto_parental select").prop('required', false);
                }
            }
        }, '.birthday_member');
    });

    /**
     * Fonction pour modifier un champ avec les boutons toggle
     * @param p
     */
    function toggleEdit(p) {
        $.ajax({
            type: "POST",
            url: p.url,
            dataType: 'json',
            data: 'id=' + p.id + '&field=' + p.field + '&value=' + p.value + '&entity=' + p.entity,
            success: function (msg) {
                if (msg.error) {
                    showAlert({
                        class: 'alert-danger',
                        message: 'Une erreur est survenue'
                    });
                } else {
                    p.thisElt.parents('tr').find('td').effect('highlight', {}, 1000);
                }
            }
        });
    }


    /**
     * toggle search bar
     */
    $(document).ready(function () {

        $(document).on({
            click: function () {
                $(this).parent().toggleClass('open')
                if ($(this).parent().hasClass('open')) {
                    $(this).parent().find('input').focus();
                    $(this).removeClass('fa-search');
                    $(this).addClass('fa-times');
                } else {
                    $(this).addClass('fa-search');
                    $(this).removeClass('fa-times');
                }
                if ($(this).parent().find('#search_result_content').is(':visible')) {
                    $(this).parent().find('#search_result').hide();
                    $(this).parent().find('#search_result_back').hide();
                    $("body").removeClass("modal-open")
                }
            }
        }, '#search-btn .btn.btn-reset i');

        //setup before functions
        var typingTimer;                //timer identifier
        var doneTypingInterval = 500;  //time in ms (5 seconds)

        $(document).on({
            keyup: function () {
                clearTimeout(typingTimer);
                var thisElt = $(this);
                $('#loader').show();
                typingTimer = setTimeout(function () {
                    $.ajax({
                        type: "POST",
                        url: router.routing.generate('ajax-search'),
                        data: {'search': thisElt.val()},
                        dataType: 'json',
                        success: function (msg) {
                            $("body").addClass("modal-open")
                            $('#search_result').show();
                            $('#search_result_back').show();
                            document.getElementById('search_result').innerHTML = msg.returnHtml;
                            $('#loader').hide();
                        }
                    });
                }, doneTypingInterval);
            }
        }, '#search-btn input');

    });

    $('div.dataTables_filter input').addClass('searchBar');
    $(".searchBar:not('#family-desc .searchBar, #member-template .searchBar , #animal-template .searchBar')").focus()
})
;

/***************************
 * Dans quelle vue de bootstrap on est
 */
var viewport_xs_max = 576;
var viewport_sm_max = 768;
var viewport_md_max = 992;
var viewport_lg_max = 1200;

global.getBootstrapViewport = function () {
    if ($(window).width() < viewport_xs_max) {
        return 'xs';
    } else if ($(window).width() >= viewport_xs_max && $(window).width() < viewport_sm_max) {
        return 'sm';
    } else if ($(window).width() >= viewport_sm_max && $(window).width() < viewport_md_max) {
        return 'md';
    } else if ($(window).width() >= viewport_md_max && $(window).width() < viewport_lg_max) {
        return 'lg';
    } else {
        return 'xl';
    }
}

/***************************
 * CACHER ALERTE
 */
global.removeAlert = function (params) {
    if (typeof $(params.element) != 'undefined' && $(params.element).length) {
        $(params.element).fadeOut('slow', function () {
            $(params.element).remove();
        })
    }
}

/***************************
 * REGARDE SI IL YA  DES ALERTS AU CHARGEMENT POUR SUPPRESSION AUTO
 */
if ($('.alert-fixed').length) {
    $('.alert-fixed').each(function () {
        var thisAlert = $(this);
        setTimeout(function () {
            removeAlert({
                element: thisAlert
            });
        }, 1000);
    })
}


/***************************
 * CACHER ALERTE
 */

