// import le fichier router dans ce fichier
const router = require('../../router.js');
// permet son utilisation dans les fichiers twig
global.router = router;



$(document).ready(function ($) {

    if ($('#add_form').length) {

        $(document).on({
            click: function () {
                let addForm = $('.creation_form');
                $(addForm).show('slow');
                $("body").addClass("modal-open");
            }
        }, '#add_form');


        $(document).on({
            click: function () {
                let addForm = $('.creation_form');
                $(addForm).hide();
                $("body").removeClass("modal-open");
            }
        }, '.creation_form .close');
    }
    if ($('#add_form_doc').length) {

        $(document).on({
            click: function () {
                let addForm = $('.creation_form_doc');
                $(addForm).show('slow');
                $("body").addClass("modal-open");
            }
        }, '#add_form_doc');


        $(document).on({
            click: function () {
                let addForm = $('.creation_form_doc');
                $(addForm).hide();
                $("body").removeClass("modal-open");
            }
        }, '.creation_form_doc .close');
    }


/**
 * Display of organisme_checklist progress bar
 */

    var colorInc = 100 / 3;

    var val = $('#minimise_organisme_checklist .percent_progress_checklist').html();
    // console.log(val);
    if(val !== ""
        && !isNaN(val)
        && val <= 100
        && val >= 0)
    {

        var valOrig = val;
        val = 100 - val;

        if(valOrig === 0)
        {
            $("#percent-box").val(0);
            $(".progresse .percent").text(0 + "%");
        }
        else $(".progresse .percent").text(valOrig + "%");

        $(".progresse").parent().removeClass();
        $(".progresse .water").css("top", val + "%");

        if(valOrig < colorInc * 1)
            $(".progresse").parent().addClass("red");
        else if(valOrig < colorInc * 2)
            $(".progresse").parent().addClass("orange");
        else
            $(".progresse").parent().addClass("green");
    }
    else
    {
        $(".progresse").parent().removeClass();
        $(".progresse").parent().addClass("green");
        $(".progresse .water").css("top", 100 - 67 + "%");
        $(".progresse .percent").text(67 + "%");
        $("#percent-box").val("");
    }


    let sideBarLinks = $('#menu-sidebar a');
    if ($(sideBarLinks).length > 0){
        // let links = $(sideBar).find(a);


        $(document).on({
            click: function (e) {
                e.preventDefault();
                let dataLink = $(this).attr('data-link');
                $(sideBarLinks).each(function () {
                    let sideBarLink = $(this).attr('data-link');
                    let datalinkShort = dataLink.split('#')[0];
                    let datalinkAnchor = dataLink.split('#')[1];
                    let target = "";
                    if (typeof datalinkAnchor != 'undefined'){
                        target += $(this).attr('href')+'#'+datalinkAnchor
                    } else {
                        target += $(this).attr('href')
                    }
                    if (typeof sideBarLink != 'undefined'){
                        if (datalinkShort === sideBarLink){
                            window.location.href = target ;
                        }
                    }
                });
            }
        }, '#organisme_checklist a');
    }
























});


