const router = require("../../router");
$(document).ready(function () {
    if ($('#quick_add_form').length) {
        let urlsQuickAdd = {};
        urlsQuickAdd['quickAdd'] = router.routing.generate('ajax-quick-add')
        urlsQuickAdd['quickAddForm'] = router.routing.generate('ajax-quick-add-form')
        urlsQuickAdd['quickAddCoursesList'] = router.routing.generate('ajax-quick-cours-list')
        urlsQuickAdd['quickDriversList'] = router.routing.generate('ajax-quick-drivers-list')

        $(document).on({
            click: function () {
                $('#quick_add_form').hide()
                $('#quick_add_form_driver').closest('.select').hide()
                $('#quick_add_form_cour').closest('.select').hide()
                $('#quick_add_form_submit').closest('.submit').hide()
                $('#quick_add_alertes').hide()
            }
        }, '#quick_add_form .close i');


        // charge le formulaire à son ouverture
        $(document).on({
            click: function () {
                $('#loader').show();
                $.ajax({
                    type: "POST",
                    url: urlsQuickAdd['quickAddForm'],
                    dataType: 'json',
                    success: function (msg) {
                        $('#quick_add_form').show()
                        let options = '';
                        options += '<option value=""  selected="">Choisir un animal</option>';
                        for (let i = 0; i < msg.length; i++) {
                            let level = '';
                            let race = '';
                            if (typeof msg[i].level !== "undefined"){
                                level = msg[i].level.name;
                            }
                            if (typeof msg[i].race !== "undefined"){
                                race = msg[i].race.name;
                            }
                            options += '<option value="' + msg[i].id + '">' + msg[i].usual_name + ' - ' + race +' - ' + level +'</option>';
                        }
                        $('#loader').hide();
                        $('#quick_add_form_animal').html(options);
                    },
                });
            }
        }, '#quick_add_menu');


        // recup la liste des proprios de l'animal
        $(document).on({
            change: function () {
                // $('#loader').show();
                let thisElt = $(this);
                $.ajax({
                    type: "POST",
                    url: urlsQuickAdd['quickDriversList'],
                    data: 'idAnimal=' + thisElt.val(),
                    dataType: 'json',
                    success: function (msg) {
                        $('#quick_add_form_driver').closest('.select').show()
                        // pour le select
                        let options = '';
                        options += '<option value=""  selected="">Choisir le membre</option>';
                        for (let i = 0; i < msg.members.length; i++) {
                            options += '<option value="' + msg.members[i].id + '">' + msg.members[i].firstname + ' ' + msg.members[i].lastname +'</option>';
                        }
                        $('#quick_add_form_driver').html(options);


                        // pour les alertes

                        let tr = '';
                        for (let i = 0; i < msg.alerts.length; i++) {


                            let alerteDate = msg.alerts[i].date
                            let alertDateToShow = new Date(alerteDate)
                            let day = ("0" + alertDateToShow.getDate()).slice(-2);
                            let month = ("0" + (alertDateToShow.getMonth() + 1)).slice(-2);
                            let year = alertDateToShow.getFullYear();
                            let commentaire = "";
                            let day30= new Date();
                            day30.setDate(day30.getDate() + 30);
                            if (typeof msg.alerts[i].comment !== "undefined"){
                                commentaire = msg.alerts[i].comment;
                            }
                            let classColor = "ras"
                            if (alertDateToShow < new Date() ){
                                classColor = 'assurance'
                            } else if (alertDateToShow < day30 ) {
                                classColor = 'attention'
                            }

                            let animalName = '';
                            if (msg.alerts[i].animalName){
                                animalName = msg.alerts[i].animalName
                            }
                            let animalId = '';
                            if (msg.alerts[i].animalId){
                                animalId = '<a style="margin-left: 10px;" href="'+router.routing.generate('animal-template',{'id': msg.alerts[i].animalId})+'" target="_blank"><i class=" show fas fa-eye"></i></a>'
                            }


                        tr +=
                            '  <tr class="affiche '+ classColor +' ">' +
                            '      <th>' + msg.alerts[i].name +
                            '      </th>' +
                            '      <th>'  + day + "/" + month + "/" + year +
                            '      </th>' +
                            '      <th>' + msg.alerts[i].category +
                            '      </th>' +
                            '      <th>' + animalName + animalId + '</th>' +
                            '      <th>' +
                            '      <th>' + commentaire +
                            '      </th>' +
                            '      </th>' +
                            '  </tr>'
                        }
                        $('#quick_add_alertes tbody').html(tr);
                        $('#quick_add_alertes').show();
                        $('#loader').hide();
                    },
                });
            }
        }, '#quick_add_form_animal');

        // recup la liste cours du jour
        $(document).on({
            change: function () {
                $('#loader').show();
                let thisElt = $(this);
                $.ajax({
                    type: "POST",
                    url: urlsQuickAdd['quickAddCoursesList'],
                    dataType: 'json',
                    success: function (msg) {
                        $('#quick_add_form_cour').closest('.select').show()
                        let options = '';
                        options += '<option value=""  selected="">Choisir le cours</option>';
                        for (let i = 0; i < msg.length; i++) {
                            let startDate = msg[i].start;
                            let dateToShow = new Date(startDate)
                            let offset = dateToShow.getTimezoneOffset() / 60;
                            dateToShow.setHours(dateToShow.getHours() + offset);
                            let hours = dateToShow.getHours() + 1;
                            let minutes = dateToShow.getMinutes();
                            options += '<option value="' + msg[i].id + '">' + msg[i].title + ' - ' + hours  + 'h' + minutes + '</option>';

                        }
                        $('#quick_add_form_cour').html(options);
                        $('#loader').hide();
                    },
                });
            }
        }, '#quick_add_form_driver');

        // recup la liste cours du jour
        $(document).on({
            change: function () {
                $('#quick_add_form_submit').closest('.submit').show()
            }
        }, '#quick_add_form_cour');

        // valid form
        $(document).on({
            click: function () {
                $('#loader').show();
                let thisElt = $(this);
                $.ajax({
                    type: "POST",
                    url: urlsQuickAdd['quickAdd'],
                    data: {
                        animalid: $('#quick_add_form_animal').val(),
                        memberId: $('#quick_add_form_driver').val(),
                        courseid: $('#quick_add_form_cour').val(),
                    },
                    dataType: 'json',
                    success: function (msg) {
                        $('#loader').hide();
                        $('.quick_add_message').show('slow')
                        setTimeout(() => {
                            $('.quick_add_message').hide('slow')
                        }, "2000");
                    },
                });
            }
        }, '#quick_add_form_submit');

    }
});