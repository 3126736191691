// import le fichier router dans ce fichier
const router = require('../../router.js');
// permet son utilisation dans les fichiers twig
global.router = router;


$(document).ready(function ($) {
    if ($('#app_bundle_statut_type_icon')) {
        // ouvre la zone d'icones
        $(document).on({
            click: function () {
                let iconList = $('#icons_fa');
                $(iconList).toggle()
                $('#activite #form_absolute .content').animate({'height':'80vh'},500);
            }
        }, '#app_bundle_statut_type_icon');

        // ferme la zone icones si  clique en dehors
        $(document).mouseup(function (e) {
            var container = $("#icons_fa");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                container.hide();
            }
        });

        // selectionne et ferme la zone icone
        $(document).on({
            click: function () {
                let codeId = $(this).attr('data-id');
                let codeName = $(this).attr('data-name');
                let iconList = $('#icons_fa');
                let input = $('#app_bundle_statut_type_icon');

                $(input).val(codeName);
                $('.icon_display_js').html('<i class="fa fa-' + codeName + '"></i>');
                $('.icon_display_js').show();

                $(iconList).hide()
                $('#activite #form_absolute .content').css('height', 'unset')
            }
        }, '#icons_fa i');
    }


    let requestURL  = 'https://api-adresse.data.gouv.fr/search/?q='
    var select = $('#selection_address');

    $("#app_bundle_family_type_addresses_0_enterAdress").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "https://api-adresse.data.gouv.fr/search/?q="+$("#app_bundle_family_type_addresses_0_enterAdress").val(),
                data: { q: request.term },
                dataType: "json",
                success: function (data) {
                    autocompleteAdresse()
                }
            });
        },
    });

    function autocompleteAdresse() {
        var inputValue = $("#app_bundle_family_type_addresses_0_enterAdress").val()
        if (inputValue) {
            fetch(setQuery(inputValue))
                .then(function (response) {
                    response.json().then(function (data) {
                        displaySelection(data);
                    });
                });
        } else {
            $(select).hide()
        }
    }

    function selectAdresse(element) {
        $("#app_bundle_family_type_addresses_0_enterAdress").val(element.properties.name + " " + element.properties.postcode + " " + element.properties.city);
        $(select).hide()
        $("#app_bundle_family_type_addresses_0_street").val(element.properties.name);
        $("#app_bundle_family_type_addresses_0_zip").val(element.properties.postcode);
        $("#app_bundle_family_type_addresses_0_city").val(element.properties.city);
    }

    function setQuery(value) {
        return requestURL + value + "?type=housenumber&autocomplete=1";
    }

    function displaySelection(response) {

        if (Object.keys(response.features).length > 0) {

            $(select).show();
            var ul = document.createElement('ul');
            $(select).html(ul);

            response.features.forEach(function (element) {

                var li = document.createElement('li');
                var ligneAdresse = document.createElement('span');
                var infosAdresse = document.createTextNode(element.properties.postcode + ' ' + element.properties.city);

                ligneAdresse.innerHTML = element.properties.name;
                li.onclick = function () { selectAdresse(element); };
                li.appendChild(ligneAdresse);
                li.appendChild(infosAdresse);
                ul.appendChild(li);

            });
        } else {
            $(select).hide()
        }
    }





    $("#family_solo_addresses_0_enterAdress").autocomplete({
        source: function (request, response) {
            $.ajax({
                url: "https://api-adresse.data.gouv.fr/search/?q="+$("#family_solo_addresses_0_enterAdress").val(),
                data: { q: request.term },
                dataType: "json",
                success: function (data) {
                    console.log(data.features);
                    autocompleteAdresseSolo()
                }
            });
        },
    });

    function autocompleteAdresseSolo() {
        var inputValue = $("#family_solo_addresses_0_enterAdress").val()
        if (inputValue) {
            fetch(setQuerySolo(inputValue))
                .then(function (response) {
                    response.json().then(function (data) {
                        displaySelectionSolo(data);
                    });
                });
        } else {
            $(select).hide()
        }
    }

    function selectAdresseSolo(element) {
        $("#family_solo_addresses_0_enterAdress").val(element.properties.name + " " + element.properties.postcode + " " + element.properties.city);
        $(select).hide()
        $("#family_solo_addresses_0_street").val(element.properties.name);
        $("#family_solo_addresses_0_zip").val(element.properties.postcode);
        $("#family_solo_addresses_0_city").val(element.properties.city);
    }

    function setQuerySolo(value) {
        return requestURL + value + "?type=housenumber&autocomplete=1";
    }

    function displaySelectionSolo(response) {

        if (Object.keys(response.features).length > 0) {

            $(select).show();
            var ul = document.createElement('ul');
            $(select).html(ul);

            response.features.forEach(function (element) {

                var li = document.createElement('li');
                var ligneAdresse = document.createElement('span');
                var infosAdresse = document.createTextNode(element.properties.postcode + ' ' + element.properties.city);

                ligneAdresse.innerHTML = element.properties.name;
                li.onclick = function () { selectAdresseSolo(element); };
                li.appendChild(ligneAdresse);
                li.appendChild(infosAdresse);
                ul.appendChild(li);

            });
        } else {
            $(select).hide()
        }
    }































});
