// import le fichier router dans ce fichier
const router = require('../../router.js');
// permet son utilisation dans les fichiers twig
global.router = router;


$(document).ready(function () {

    if ($('.animal_type').length > 0) {
        $(document).on({
            change: function () {
                var thisElt = $(this);
                $.ajax({
                    type: "POST",
                    url: router.routing.generate('ajax-get_animal_by_species'),
                    data: 'idSpecies=' + thisElt.val(),
                    dataType: 'json',
                    success: function (msg) {
                        var options = '';
                        if (msg.races) {
                            for (var i = 0; i < msg.races.length; i++) {
                                options += '<option value="' + msg.races[i]['id'] + '">' + msg.races[i]['name'] + '</option>';
                            }
                        } else {
                            options += '<option value="">Pas de race pour cette espèce</option>';

                        }
                        let animalRaceHtml = $(thisElt).closest('fieldset').find('.animal_race');
                        $(thisElt).closest('fieldset').find('.animal_race').prop('required',true);
                        $(animalRaceHtml).html(options);
                    }
                });
            }
        }, '.animal_type');
    }
});